.hero-container-1 {
	background-repeat: no-repeat;
	background-position: 0px 50px;
	background-size: 100% 65%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
	.hero-container-1 {
		background-size: 100% 85% !important;
	}
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
	.hero-container-1 {
		background-size: 100% 65%;
	}
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}