.mw-160-px {
	max-width: 160px !important;
}

.mw-170-px {
	max-width: 170px !important;
}

.mw-180-px {
	max-width: 180px !important;
}

.mw-190-px {
	max-width: 190px !important;
}

.mw-200-px {
	max-width: 200px !important;
}

.mw-225-px {
	max-width: 225px !important;
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 576px) {  
	.mw-225-px {
		max-width: 100% !important;
	}
}



.mw-250-px {
	max-width: 250px !important;
}

.mw-300-px {
	max-width: 300px !important;
}

.mw-350-px {
	max-width: 350px !important;
}

.font-size-64-px {
	font-size: 64px !important;
}

.font-size-55-px {
	font-size: 55px !important;
}

.font-size-1-em {
	font-size: 1em !important;
}

.font-size-1-2-em {
	font-size: 1.2em !important;
}

.font-size-1-3-em {
	font-size: 1.3em !important;
}

.font-size-1-4-em {
	font-size: 1.4em !important;
}

.font-size-1-5-em {
	font-size: 1.5em !important;
}

.font-size-2-em {
	font-size: 2em !important;
}

.font-size-2-5-em {
	font-size: 2.5em !important;
}

.font-size-3-em {
	font-size: 3em !important;
}

.font-size-3-5-em {
	font-size: 3.5em !important;
}

.font-size-4-em {
	font-size: 4em !important;
}

.font-size-5-em {
	font-size: 5em !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.lh-1-2-em {
	line-height: 1.2em;
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 768px) {  
	.lh-1-2-em {
		line-height: 1.1em !important;
	}
}

.lh-1-3-em {
	line-height: 1.3em !important;
}

.lh-1-4-em {
	line-height: 1.4em !important;
}

.lh-1-5-em {
	line-height: 1.5em !important;
}


